import Accounting from 'accounting';
import { isNullOrUndefined } from 'util';
import PaymentHelper from './payment';

const toNumber = (value) => {
    const number = isNullOrUndefined(value) ? 0 : value;
    return parseFloat(Accounting.unformat(number));
};
const getAmortizations = (amountParam, termParam, aprParam, extraPaymentParam) => {
    let extraPayment = toNumber(extraPaymentParam);
    const amount = toNumber(amountParam);
    const term = toNumber(termParam);
    const apr = toNumber(aprParam);

    // eslint-disable-next-line no-array-constructor
    const monthArray = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December');
    const amortizationsByYear = {};
    const d = new Date();
    let payment = PaymentHelper.getPayment(amount, term, apr);
    let balance = amount;
    let interest = 0.0;
    let principal = 0.0;
    let yeartotalinterest = 0.0;
    let yeartotalprincipal = 0.0;
    let totalinterest = 0.0;
    let totalprincipal = 0.0;
    let month = d.getMonth();
    let year = d.getFullYear();

    for (let i = 1; i <= term; i++) {
        interest = balance * apr;

        if (payment + extraPayment > balance) {
            if (payment > balance) {
                extraPayment = 0;
                // final payment will still have interest due on it
                payment = balance + interest;
            } else {
                extraPayment = balance - payment;
            }
        }

        principal = payment + extraPayment - interest;

        balance -= principal;

        // eslint-disable-next-line eqeqeq
        if (Math.round(balance - interest) == 0) {
            balance = 0; 
        }

        if (typeof amortizationsByYear[year] == 'undefined') {
            // eslint-disable-next-line no-array-constructor
            amortizationsByYear[year] = new Array();
            yeartotalinterest = 0;
            yeartotalprincipal = 0;
        }
        yeartotalprincipal += principal;
        yeartotalinterest += interest;
        totalprincipal += principal;
        totalinterest += interest;

        amortizationsByYear[year].push({
            monthYear: `${monthArray[++month % 12]}/${year}`,
            namedMonth: monthArray[month % 12],
            month: month % 12,
            year,
            payment: Math.round(payment * 100) / 100,
            extraPayment,
            principal: Math.round(principal * 100) / 100,
            interestPaid: Math.round(interest * 100) / 100,
            yearTotalInterest: Math.round(yeartotalinterest * 100) / 100,
            yearTotalPrincipal: Math.round(yeartotalprincipal * 100) / 100,
            totalInterest: Math.round(totalinterest * 100) / 100,
            totalPrincipal: Math.round(totalprincipal * 100) / 100,
            balance: balance < 0 ? 0 : Math.round(balance * 100) / 100,
        });
        if (balance === 0) {
            break; 
        }

        // eslint-disable-next-line eqeqeq
        if (month % 12 == 11) {
            year++; 
        }
    }
    return amortizationsByYear;
};
const renderDate = (year, month, date) => {
    const rdate = new Date(year, month, date).toISOString().slice(0, 10);
    const rdateArray = rdate.split('-');
    return rdateArray;
};
const getAprAmortizations = (amountParam, extraPaymentParam, payBack, period, ratePerPeriod) => {
    const months = period;
    const payment = extraPaymentParam;
    const amortizationsData = {};
    let m;
    let balance = toNumber(amountParam);
    let beginningBalance = toNumber(amountParam);
    const currentDate = new Date();
    const firstYear = currentDate.getFullYear();
    const firstMonth = currentDate.getMonth() + 1;
    const firstDate = currentDate.getDate();
    const monthInterval = 12;
    const twoWeekInterval = 26;
    const daysPerTwoWeek = 14;
    let showDate;
    let newMonth;
    let newDateFormat;
    let newDay;
    for (m = 0; m < months; m++) {
        const toInterest = balance * ratePerPeriod;
        const toPrincipal = payment - toInterest;
        balance -= toPrincipal;
        if (payBack === monthInterval) {
            newMonth = firstMonth + m;
            newDateFormat = renderDate(firstYear, newMonth, 1);
            showDate = `${newDateFormat[1]} -01- ${newDateFormat[0]}`;
        }
        if (payBack === twoWeekInterval) {
            newDay = firstDate + daysPerTwoWeek * m;
            newDateFormat = renderDate(firstYear, firstMonth, newDay);
            showDate = `${newDateFormat[1]} -${newDateFormat[2]}- ${newDateFormat[0]}`;
        }
        if (typeof amortizationsData[newDateFormat[0]] == 'undefined') {
            amortizationsData[newDateFormat[0]] = [];
        }
        if (balance < 0) {
            balance = 0;
        }
        amortizationsData[newDateFormat[0]].push({
            totalprincipal: toPrincipal,
            totalinterest: toInterest,
            totalbalance: balance,
            rbalance: beginningBalance,
            labelno: m + 1,
            labelnewdate: showDate,
        });
        beginningBalance = balance;
    }
    return amortizationsData;
};

export default {
    amortizationBreakdown(LoanAmount, LoanTerm, InterestRate) {
        return getAmortizations(LoanAmount, PaymentHelper.convertLoanTermInMonths(LoanTerm), PaymentHelper.convertInterestRatePerMonths(InterestRate), 0);
    },
    getAprAmortizations(LoanAmount, ExtraPaymentParam, PayBack, Period, RatePerPeriod) {
        return getAprAmortizations(LoanAmount, ExtraPaymentParam, PayBack, Period, RatePerPeriod);
    },
};
