import { render, staticRenderFns } from "./amortization-breakdown-table.vue?vue&type=template&id=5278481c"
import script from "./amortization-breakdown-table.vue?vue&type=script&lang=js"
export * from "./amortization-breakdown-table.vue?vue&type=script&lang=js"
import style0 from "./amortization-breakdown-table.vue?vue&type=style&index=0&id=5278481c&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "src/shared/components/amortization-breakdown-table.vue"
export default component.exports