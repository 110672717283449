var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-calculator-layout",
    {
      class: _vm.calcMainClass,
      attrs: {
        "filter-width": _vm.filterWidth,
        "output-width": _vm.outputWidth,
        "calculator-title": _vm.calculatorTitle,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "filter" }, slot: "filter" },
        [
          _c("number-input", {
            attrs: {
              label: _vm.labelLoanAmount,
              "tooltip-link": _vm.LoanAmountTooltip,
              type: "text",
            },
            model: {
              value: _vm.LoanAmount,
              callback: function ($$v) {
                _vm.LoanAmount = $$v
              },
              expression: "LoanAmount",
            },
          }),
          _vm._v(" "),
          _c("number-input", {
            attrs: {
              label: _vm.labelInterestRate,
              symbol: "%",
              "symbol-aligned": "right",
              precision: "3",
              maxValue: "100.000",
              type: "number",
              "tooltip-link": _vm.InterestRateTooltip,
            },
            model: {
              value: _vm.InterestRate,
              callback: function ($$v) {
                _vm.InterestRate = $$v
              },
              expression: "InterestRate",
            },
          }),
          _vm._v(" "),
          _c("drop-down", {
            attrs: {
              label: _vm.labelLoanTermInYears,
              "tooltip-link": _vm.LoanTermTooltip,
              options: _vm.LoanTermInYearsList,
            },
            model: {
              value: _vm.LoanTermInYears,
              callback: function ($$v) {
                _vm.LoanTermInYears = $$v
              },
              expression: "LoanTermInYears",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { attrs: { slot: "output" }, slot: "output" }, [
        _c(
          "div",
          {
            staticClass:
              "text-center calculate-amount-section loan-payment-section",
          },
          [
            _c("h3", [_vm._v("Your monthly payment will be:")]),
            _vm._v(" "),
            _c("span", { staticClass: "calculate-amount" }, [
              _vm._v(_vm._s(_vm.monthlyPayment)),
            ]),
            _vm._v(" "),
            _c("h4", { staticClass: "next-step" }, [
              _vm._v("Ready for the next step?"),
            ]),
            _vm._v(" "),
            _c("div"),
            _vm._v(" "),
            _c(
              "button",
              {
                class: _vm.destinationButtonCssClass,
                attrs: { type: "submit" },
                on: {
                  click: function ($event) {
                    _vm.DestinationTarget
                      ? _vm.redirect(_vm.DestinationTarget)
                      : _vm.redirect()
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.DestinationButtonText) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.disclosureText
                  ? _c("advertising-disclosures", {
                      attrs: {
                        "disclosure-text": _vm.DisclosureText,
                        "disclosure-tokens": _vm.DisclosureTokens,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.ltLogo == 1
              ? _c("lt-logo", [
                  _c("small", [_vm._v("NMLS#1136 Terms & Conditions Apply")]),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _vm.AmortizationBreakdown
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-xs-12" },
                [
                  _c("amortization-breakdown-table", {
                    attrs: {
                      "loan-amount": _vm.LoanAmount,
                      "loan-term-in-years": _vm.LoanTermInYears,
                      "interest-rate": _vm.InterestRate,
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }